<template>
  <div class="my-subscriptions">
    <div class="my-subscriptions__content">
      <div class="sub-type">
        <h4>Активна пакетная подписка</h4>
      </div>
      <Checkbox v-model="subscription">
        Отменить подписку
      </Checkbox>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/gui/checkbox/Checkbox';
export default {
  name: 'MySubscriptions',
  components: { Checkbox },
  data() {
    return {
      subscription: true
    };
  }
};
</script>

<style lang="scss">
.my-subscriptions {
  &__content {
  }
}
</style>
