<template>
  <div class="my-orders">
    <div class="my-orders__content">
      <ul>
        <li v-for="order in orders" :key="order.orderId">
          <div class="order-id">
            {{ order.orderId }}
          </div>
          <div class="cad-number">
            {{ order.cadNumber }}
          </div>
          <div class="address">
            {{ order.address }}
          </div>
          <div class="link-html">
            <a :href="order.linkHTML">Скачать html</a>
          </div>
          <div class="link-pdf">
            <a :href="order.linkPDF">Скачать pdf</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyOrders',
  data() {
    return {
      orders: [
        {
          orderId: 1,
          cadNumber: '52:18:0060052:601',
          address: 'г Нижний Новгород, ул Нижегородская, д 1',
          linkHTML: 'http://localhost:8080/HTML',
          linkPDF: 'http://localhost:8080/PDF'
        },
        {
          orderId: 2,
          cadNumber: '52:18:0060052:602',
          address: 'г Нижний Новгород, ул Нижегородская, д 2',
          linkHTML: 'http://localhost:8080/HTML',
          linkPDF: 'http://localhost:8080/PDF'
        },
        {
          orderId: 3,
          cadNumber: '52:18:0060052:603',
          address: 'г Нижний Новгород, ул Нижегородская, д 3',
          linkHTML: 'http://localhost:8080/HTML',
          linkPDF: 'http://localhost:8080/PDF'
        },
        {
          orderId: 4,
          cadNumber: '52:18:0060052:604',
          address: 'г Нижний Новгород, ул Нижегородская, д 4',
          linkHTML: 'http://localhost:8080/HTML',
          linkPDF: 'http://localhost:8080/PDF'
        },
        {
          orderId: 5,
          cadNumber: '52:18:0060052:605',
          address: 'г Нижний Новгород, ул Нижегородская, д 5',
          linkHTML: 'http://localhost:8080/HTML',
          linkPDF: 'http://localhost:8080/PDF'
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.my-orders {
  &__content {
    ul {
      list-style: none;

      li {
        display: grid;
        grid-template-columns: 50px 200px 1fr 120px 120px;
        gap: 10px;
        padding: 10px 0;

        @media screen and (max-width: 768px) {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 5px;
          border-top: 2px solid #00bc8a;

          &:last-of-type {
            border-bottom: 2px solid #00bc8a;
          }

          .order-id {
            display: none;
          }

          .cad-number,
          .address {
            width: 100%;
          }

          .link-html,
          .link-pdf {
            width: 45%;
          }
        }

        a {
          color: $brand;
          text-decoration: none;
          border-bottom: 1px dotted $brand;
        }
      }
    }
  }
}
</style>
