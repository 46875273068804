<template>
  <div class="tabs">
    <div class="tabs-header">
      <div class="tabs-header__title">
        <button
          :class="{ active: activeTab === 0 }"
          @click="$emit('change-active-tab', 0)"
        >
          Профиль
        </button>
        <button
          :class="{ active: activeTab === 1 }"
          @click="$emit('change-active-tab', 1)"
        >
          Мои заказы
        </button>
        <button
          :class="{ active: activeTab === 2 }"
          @click="$emit('change-active-tab', 2)"
        >
          Мои подписки
        </button>
      </div>
    </div>
    <div class="tabs-content">
      <component :is="currentTabComponent" />
    </div>
  </div>
</template>

<script>
import PersonalData from '@/components/views/local-cabinet/tabs/tabs-content/PersonalData';
import MyOrders from '@/components/views/local-cabinet/tabs/tabs-content/MyOrders';
import MySubscriptions from '@/components/views/local-cabinet/tabs/tabs-content/MySubscriptions';

import './tabs.scss';

export default {
  name: 'Tabs',
  components: {
    PersonalData,
    MyOrders,
    MySubscriptions
  },
  props: {
    activeTab: {
      type: Number,
      default: 0
    }
  },
  computed: {
    currentTabComponent() {
      let componentName = '';

      switch (this.activeTab) {
        case 0:
          componentName = 'PersonalData';
          break;

        case 1:
          componentName = 'MyOrders';
          break;

        case 2:
          componentName = 'MySubscriptions';
          break;

        default:
          componentName = 'PersonalData';
          break;
      }

      return componentName;
    }
  }
};
</script>
