<template>
  <div class="personal-data">
    <form class="local-cabinet__form" @submit.prevent="validate">
      <Field
        v-model="form.email"
        class="form__input-field"
        title="E-mail *"
        type="email"
        name="email"
        :error="formErrors.email"
      />
      <Field
        v-model="form.phone"
        class="form__input-field"
        title="Телефон"
        :error="formErrors.phone"
      />
      <Button type="submit">
        Сохранить
      </Button>
    </form>
  </div>
</template>

<script>
import Field from '@/components/gui/field/Field';
import Button from '@/components/gui/button/Button';

import Validation from '@/ext/validation/ValidationWithFocus';

export default {
  name: 'PersonalData',
  components: {
    Field,
    Button
  },
  data() {
    return {
      formRules: {
        email: [Validation.REQUIRED, Validation.EMAIL],
        phone: [Validation.PHONE]
      },
      form: {
        email: '',
        phone: ''
      },
      formErrors: {
        email: '',
        phone: ''
      }
    };
  },
  methods: {
    validate(submit = true) {
      // this.formIsSubmit = true;
      const selector = submit ? '.field_error input' : null;
      const validation = new Validation(
        selector,
        this.form,
        this.formRules
      ).make();

      this.formErrors = validation.errors;

      submit && validation.isValid && this.submit();
    }
  }
};
</script>

<style lang="scss">
.personal-data {
  form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 700px;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 640px) {
      flex-direction: column;
    }

    .field {
      width: calc(50% - 20px);

      @media screen and (max-width: 640px) {
        width: 70%;
      }

      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }

    button {
      display: block;
      margin: 0 auto;
    }
  }
}
</style>
